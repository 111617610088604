@import "src/styles/variables";

.btnBack {
	margin: $padding / 2 0 $padding / 2 $padding / -2;
	height: 50px;

	@include media("<tablet") {
		margin: 0 0 0 $padding * -1;
	}
}
.embed {
	cursor: pointer;
	margin-bottom: 30px;
	color: $grey;
	margin-left: 40px;
}
.title {
	flex: 1;
	align-self: center;
	margin-left: 20px;
	color: $primary;
	font-size: 18px;

	@include media("<tablet") {
		margin: 0;
		font-size: 16px;
		text-align: right;
	}
}

.videoresponsive {
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	height: 0;
}

.videoResponsiveIframe {
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	position: absolute;
}

.uplct {
	display: flex;
	flex-direction: column;
	width: 50%;
}
.form {
	background-color: white;
	display: flex;
	@include media(">tablet") {
		flex-direction: row;
	}
}

.upload {
	flex: 1;
	margin-left: 40px;

	img {
		max-width: 300px;
		max-height: 300px;
		object-fit: contain;
	}
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: $padding * 2;

	@include media("<=tablet") {
		margin-top: $padding * 2;
		margin-left: 0;
	}
}

.inputTitre {
	margin-top: 0;
}

.btnSubmit {
	@include media(">tablet") {
		align-self: flex-start;
	}
}
