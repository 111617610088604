@import 'src/styles/variables';

.wrapper {
	flex-direction: column;
}

.inputsWrapper {
	display: flex;
	align-items: center;
	font-size: 16px;
}

.input {
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
}
