.container {
  background-color: white;
  padding: 40px;
  margin: 40px;
}

.h1 {
  margin-bottom: 30px;
}

.dropzone {
  margin-top: -11px; // to counter the "margin: 15px" in Input.module.scss, a component shouldn’t have external margins !
  width: 294px;
  height: 168px;
  margin-bottom: 50px;

  img {
    max-width: 294px;
    max-height: 168px;
    object-fit: fill;
  }
}

.buttonAdd {
  display: flex;
  justify-content: center;
  width: 100%
}

label {
  color: #c78786;
}

.produits {
  margin-top: 5px;
}

.containerPopin {
  width: 200px;
  height: 100px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40%;
}
