@import 'src/styles/variables';

.client {
	color: $grey;
	line-height: 1.3;

	strong {
		color: $text;
	}
}

.telephone {
	display: block;
	color: $grey;
	font-size: 12px;
	white-space: nowrap;
}

.produits {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-width: 300px;

	color: $grey;
	font-size: 13px;
	line-height: 1.5;

	overflow: hidden;
}

.facture {
	height: 38px;
	width: 30px;
}
