@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 $padding 50px;
}

.logo {
	width: 225px;
	margin: 20px 0;
	z-index: 3;

	@include media('>laptop') {
		width: 300px;
		margin: 50px 0;
	}
}

.content {
	position: relative;
	width: 100%;
	max-width: 500px;
	background-color: #fff;
}
