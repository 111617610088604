@import 'src/styles/variables';
@import '../../../Common/SideMenuWrapper/break';

.menu {
	border-right: 2px solid $background;
	z-index: 13;
}

.sousCategories {
	@include media('>=laptop') {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding-top: $padding * 2;
		z-index: 12;
		transform: translateX(100%);
		transition: 0.3s transform $quint;
	}

	&:not(.visible) {
		display: none;

		@include media('>=laptop') {
			display: block;
			transform: none;
		}
	}
}

.cache {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(#000, 0.6);
	z-index: 11;
	cursor: default;
	transition: 0.3s opacity;

	@include media('<laptop') {
		z-index: 3;
		display: none;
	}

	.sousCategories:not(.visible) + & {
		opacity: 0;
		z-index: 3;
		pointer-events: none;
	}
}
