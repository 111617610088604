@import 'src/styles/variables';

.buttonWrapper {
	margin-bottom: $padding * 2;
	text-align: center;

	@include media('<laptop') {
		margin-top: $padding * 2;
	}
}
.title{
	font-size: 39px;
}
.container{
	width: 92%;
	margin: 0 auto;
}
