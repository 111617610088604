@import 'src/styles/variables';

.contact {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid $line;
}

.small {
	flex: 0 0 calc(33.33% - #{$padding});

	@include media('<tablet') {
		flex: 100%;
	}
}

.big {
	flex: 0 0 calc(50% - #{$padding});

	@include media('<tablet') {
		flex: 100%;
	}
}

.addBtn {
	margin: auto;
}
