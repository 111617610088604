@import 'src/styles/variables';

$transitionDuration: 200;
:export {
	duration: $transitionDuration;
}

.wrapper {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: $padding $padding $padding * 4;
	z-index: 1000;
	overflow-y: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.cache {
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba($black, 0.7);
	transition: $transitionDuration * 1ms opacity;

	.enter:not(.enterActive) &,
	.exit & {
		opacity: 0;
	}
}

.content {
	position: relative;
	max-width: 500px;
	margin: auto;
	background-color: white;
	transition-duration: $transitionDuration * 1ms;
	transition-property: opacity, transform;

	.enter:not(.enterActive) &,
	.exit & {
		opacity: 0;
		transform: translateY(20px);
	}
}
