@import "src/styles/variables";

.wrapper{
	background-color: transparent;
}
.wrapperHeader{
	background-color: white;
}

.btnBack {
	margin: $padding / 2 0 $padding / 2 $padding / -2;
	height: 80px;

	@include media("<tablet") {
		margin: 0 0 0 $padding * -1;
	}
}

.title {
	flex: 1;
	align-self: center;
	margin-left: 20px;
	color: $primary;
	font-size: 18px;

	@include media("<tablet") {
		margin: 0;
		font-size: 16px;
		text-align: right;
	}
}

.form {
	background-color: white;
	flex-direction: column;
	width: 48%;
	@include media("<=laptop") {
		width: 100%;
	}
}

	@include media(">tablet") {
		flex-direction: row;
	}

.newform {
	background-color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: center;

	// @include media(">tablet") {
	// 	flex-direction: row;
	// }
}
.upload {
	flex: 1;

	img {
		width: 100%;
		min-height: 300px;
	}
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: $padding * 2;

	@include media("<=tablet") {
		margin-top: $padding * 2;
		margin-left: 0;
	}
}

.inputTitre {
	margin-top: 0;
}

.btnSubmit {
	// margin: 40%;
	display: block;
	margin: 0 auto;
	@include media(">tablet") {
		align-self: flex-start;
	}
	@include media("<=mobile") {
		align-self: center;
		margin-left: 0;
	}
}
.photo {
	margin-top: 2%;
	width: 300px;
	height: 230px;
	h1 {
		color: #646464;
		font-size: 1rem;
		line-height: 1;
		padding-bottom: 10px;
		cursor: pointer;
		transition: 0.2s color;
	}
	img {
		max-width: 300px;
		max-height: 200px;
		object-fit: cover;
	}
}
.span {
	color: red;
}

.kbis {
	width: 300px;
	height: 230px;
	h1 {
		color: #646464;
		font-size: 1rem;
		line-height: 1;
		padding-bottom: 10px;
		cursor: pointer;
		transition: 0.2s color;
	}
	img {
		max-width: 300px;
		max-height: 200px;
		object-fit: cover;
	}
}
.label {
    display: flex;
    align-items: center;
	justify-content: flex-end;
	flex-direction: row-reverse;
    grid-gap: 10px;
    gap: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	padding-bottom: 5px;
	padding-right: 10px;
	cursor: pointer;
	transition: 0.2s color;
}
.banque {
	display: flex;
	width: 100%;
	:nth-child(1) {
		width: 100%;
	}
	:nth-child(2) {
		width: 30%;
	}
	:nth-child(3) {
		width: 30%;
	}
}

.container {
	display: grid;
	// grid-template-columns: repeat(2, 1fr);
	// grid-template-rows: 1fr;
	grid-column-gap: 50px;
	// grid-row-gap: 0px;

	@include media("<=laptop") {
		display: flex;
		flex-direction: column;
	}
}
.table1 {
	background-color: white;
	padding: $padding * 2;
	grid-column: 1/2;
	// width: 50%;
}

.table2 {
	display: flex;
	justify-content: center;
	background-color: white;
	padding: $padding * 2;
	grid-column: 2/2;
	// width: 50%;
}

.tableTitle{
	font-weight: 500;
	margin-bottom: 10px;
}
.btnCtc {
	border: 1px solid black;
	cursor: pointer;
	width: 20%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input {
	border: 1px solid black;
}
.ctnCtc {
	margin-top: 15px !important;
	background-color: #f7f8f9;
	padding: 10px 20px;
}
.deleteBtn {
	position: absolute;
	right: 60px;
	cursor: pointer;
}
.labels {
	padding: 1rem 0;
	input {
		margin-left: 10px;
		margin-top: 5px;
	}
}

.labelsContainer{
    display: flex;
}
.disabled {
	display: flex;
	input {
		margin-left: 10px;
		margin-top: 5px;
	}
}

.select {
	height: 40px;
	padding: 0 15px;
	color: #03142f;
	border: 1px solid #d8d8d8;
	background-color: #f7f8f9;
	transition: 0.2s border-color;
	-webkit-appearance: none;
	appearance: none;
	margin-bottom: 1%;
	// min-width: 240px;
}
.h {
	margin-top: 25px;
	display: block;
	padding-bottom: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}
.hh {
	display: block;
	padding-bottom: 10px;
	color: #c78786;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}

i{
	position: absolute;
	display: block;
	transform: translate(0, -50%);
	top: 50%;
	pointer-events: none;
	width: 25px;
	text-align: center;
	font-style: normal;
	right: 0;
}

.tarification{
	margin-top: 25px;
}

.aVenir{
	opacity: 0.5;
}
.tarificationAjouterButtonContainer{
	display: flex;
	justify-content: space-between;
}
.tarificationAjouterButton{
	background-color: white;
	color: #646464;
	border: 1px solid #646464
}

.error {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $red;
	font-size: 13 + 0px;
	line-height: 1.3;
	text-align: center;
	transition-property: height, opacity;
}

.labels {
	padding: 1rem 0;
	input {
		margin-left: 10px;
		margin-top: 5px;
	}
}

.label {
    display: flex;
	flex-basis: 50%;
	flex-grow: 1;
    // align-items: center;
    gap: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	// padding-bottom: 5px;
	// padding-right: 10px;
	cursor: pointer;
	transition: 0.2s color;
}


.labelsContainer{
    display: flex;
	flex-wrap: wrap;
}
.formContainer{
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}
