@import 'src/styles/variables';

.btn {
	position: relative;
	padding: 0 $padding;
	text-align: center;
	background-color: white;
	transition: $duration background-color;

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}

	&.isPanierOpen {
		background-color: $primary;
	}
}

.svg {
	margin-top: 6px;
	width: 32px;
	stroke: $secondary;
	transition: $duration stroke;
	z-index: 2;

	@include media('<laptop') {
		stroke: $primary;
	}

	@include ie {
		vertical-align: -20px;
	}

	.isPanierOpen & {
		stroke: white;
	}
}
