@import 'src/styles/variables';

.buttonWrapper {
	margin-bottom: $padding * 2;
	text-align: center;

	@include media('<laptop') {
		margin-top: $padding * 2;
	}
}
