@import "src/styles/variables";

.fournisseur {
	margin-right: auto;
	color: #d0021b;
}

.wrapper {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	padding: $padding;
	padding-top: 0 !important;
	color: $grey;

	strong {
		color: $text;
		font-size: 13px;
	}

	& ~ & {
		@include media(">=laptop") {
			margin-top: 0;
		}
	}

	@include media("<laptop") {
		border-bottom: 2px solid $primary;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: -13px;
			width: 0;
			height: 0;
			margin: auto;
			border: 9px solid transparent;
			border-bottom: none;
			border-top: 13px solid $primary;
		}
	}
}
.mobileTitle {
	display: flex;
	align-items: center;
	
	.photo {
		margin-right: 10px;
	}
	@media screen and (max-width: 460px) {
		margin-right: 20px;
		div figure{
			margin-right: 70px;
			margin-left: 5px;
		}
		
	  }
}

//.photo {
//	width: 80px;
//	height: 60px;
//}

.content {
	width: 10px;
	height: 10px;
	overflow: hidden;
}

.nom {
	font-weight: 600;
	text-transform: uppercase;
}

.photo {
	height: 50px;
	width: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		height: 30px;
		width: 30px;
	}
}

.warning {
	color: $grey;
	font-size: 12px;
}

.big {
	font-size: 18px;
}
