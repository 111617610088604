@import 'src/styles/variables';

.separator {
	width: 2px;
	height: auto;
	margin: 15px 0;
	border: none;
	background-color: $line;
}

.wrapper {
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	padding-left: $padding;
	line-height: 1.3;
	overflow: hidden;
}

.type {
	color: $primary;
	font-size: 12px;
}

.value {
	font-size: 18px;
	font-weight: 600;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

// .logo {
// 	@include media('<tablet') {
// 		width: 170px;
// 	}
// }
