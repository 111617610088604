@import 'src/styles/variables';

.buttonWrapper {
    margin-bottom: $padding * 2;
    text-align: center;

    @include media('<laptop') {
        margin-top: $padding * 2;
    }

}

.contaier {
    width: 80%;
    margin: auto;
    margin-top: 50px;
}


.wrapper {
    display: flex;
    align-items: center;
    padding: 0 $padding;
    font-size: 13px;
    border-right: 1px solid $background;
    transition-duration: $duration;
    transition-property: color, background-color;
    div{
        cursor: pointer;
        padding: 4px 10px;
        margin-left: 10px;
        border: 1px solid black;
    }
    &:hover {
        background-color: $ultraLightGrey;
    }
}

.selected {
    color: $primary;
}
