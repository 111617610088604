@import "src/styles/variables";

.btnBack {
	margin: $padding / 2 0 $padding / 2 $padding / -2;
	height: 80px;

	@include media("<tablet") {
		margin: 0 0 0 $padding * -1;
	}
}

.title {
	flex: 1;
	align-self: center;
	margin-left: 20px;
	color: $primary;
	font-size: 18px;

	@include media("<tablet") {
		margin: 0;
		font-size: 16px;
		text-align: right;
	}
}

.form {
	background-color: white;
	flex-direction: column;

	@include media(">tablet") {
		flex-direction: row;
	}
}

.newform {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;

	// @include media(">tablet") {
	// 	flex-direction: row;
	// }
}
.upload {
	flex: 1;

	img {
		width: 100%;
		min-height: 300px;
	}
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: $padding * 2;

	@include media("<=tablet") {
		margin-top: $padding * 2;
		margin-left: 0;
	}
}

.inputTitre {
	margin-top: 0;
}

.btnSubmit {
	margin-left: 40%;
	@include media(">tablet") {
		align-self: flex-start;
	}
}
