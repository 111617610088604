@import "src/styles/variables";

.btnBack {
	margin: $padding / 2 0 $padding / 2 $padding / -2;
	height: 80px;

	@include media("<tablet") {
		margin: 0 0 0 $padding * -1;
	}
}
.checkbox {
	margin-left: 2%;
	margin-bottom: 2%;
}
.title {
	flex: 1;
	align-self: center;
	margin-left: 20px;
	color: $primary;
	font-size: 18px;

	@include media("<tablet") {
		margin: 0;
		font-size: 16px;
		text-align: right;
	}
}

.form {
	background-color: white;
	flex-direction: column;

	@include media(">tablet") {
		flex-direction: row;
	}
}

.newform {
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;

	// @include media(">tablet") {
	// 	flex-direction: row;
	// }
}
.h1 {
	margin-top: 3%;
}
.upload {
	flex: 1;

	img {
		width: 100%;
		min-height: 300px;
	}
}

.right {
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: $padding * 2;

	@include media("<=tablet") {
		margin-top: $padding * 2;
		margin-left: 0;
	}
}

.inputTitre {
	margin-top: 0;
}

.btnSubmit {
	margin-left: 40%;
	@include media(">tablet") {
		align-self: flex-start;
	}
	@include media("<=mobile") {
		align-self: center;
		margin-left: 0;
	}
}

.image {
	width: 200px;
	height: 200px;
	img {
		max-height: 200px;
		max-width: 200px;
	}
}
.select {
	height: 40px;
	padding-left: 15px;
	color: #03142f;
	border: 1px solid #d8d8d8;
	background-color: #f7f8f9;
	transition: 0.2s border-color;
	-webkit-appearance: none;
	appearance: none;
}

.heure {
	height: 40px;
	padding-left: 15px;
	color: #03142f;
	border: 1px solid #d8d8d8;
	background-color: #f7f8f9;
	transition: 0.2s border-color;
	-webkit-appearance: none;
	appearance: none;
}

.franco {
	display: flex;
	select {
		padding-right: 5%;
		margin-right: 2%;
	}
	@include media("<=mobile") {
		flex-direction: column;
	}
}
.btnCtc {
	border: 1px solid black;
	cursor: pointer;
	width: 20%;
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.input {
	border: 1px solid black;
}
.ctnCtc {
	margin-top: 15px !important;
	background-color: #f7f8f9;
	padding: 10px 20px;
}
.deleteBtn {
	position: absolute;
	right: 60px;
	cursor: pointer;
}
.paie {
	height: 40px;
	padding-left: 15px;
	color: #03142f;
	border: 1px solid #d8d8d8;
	background-color: #f7f8f9;
	transition: 0.2s border-color;
	-webkit-appearance: none;
	appearance: none;
	margin-bottom: 1%;
}
.secteur {
	height: 40px;
	padding-left: 15px;
	color: #03142f;
	border: 1px solid #d8d8d8;
	background-color: #f7f8f9;
	transition: 0.2s border-color;
	-webkit-appearance: none;
	appearance: none;
	margin-bottom: 1%;
}
.h {
	margin-top: 25px;
	display: block;
	padding-bottom: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}
.hh {
	display: block;
	padding-bottom: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}
.container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-gap: 50px;

	@include media("<=laptop") {
		display: flex;
		flex-direction: column;
	}
}
.table1 {
	grid-column: 1/2;
	width: 90%;
}
.label {
	display: block;
	padding-bottom: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}
.table2 {
	grid-column: 2/2;
	width: 90%;
}
.galerie {
	h1 {
		display: block;
		padding-bottom: 10px;
		color: #646464;
		font-size: 1rem;
		line-height: 1;
		cursor: pointer;
		transition: 0.2s color;
	}
	display: flex;
	margin-top: 2%;
	:nth-child(2) {
		margin-top: 1%;
		margin-left: 1%;
	}
	:nth-child(3) {
		margin-top: 1%;
		margin-left: 1%;
	}
	@include media("<=mobile") {
		display: flex;
		flex-direction: column;
	}
}
.prelevement {
	display: flex;
	input {
		margin-left: 10px;
	}
}
.contact {
	display: flex;
	input {
		padding-right: 5%;
		margin-right: 5%;
	}
	@include media("<=mobile") {
		flex-direction: column;
	}
}
.contact2 {
	display: flex;
	input {
		padding-right: 5%;
		margin-right: 5%;
	}
	@include media("<=mobile") {
		flex-direction: column;
	}
}
.span {
	color: red;
}
