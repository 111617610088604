@import 'src/styles/variables';
@import '../../Common/SideMenuWrapper/break';

.link {
	display: flex;
	align-items: center;
	padding: 10px $padding * 2;
	font-size: 16px;
	text-transform: uppercase;
	transition: $duration color;

	&:first-child {
		margin-top: 30px;
	}

	@include media('<#{$break}') {
		padding-left: $padding;
		padding-right: $padding;
	}

	&:hover,
	&.active {
		color: $primary;
	}
}

.sub_link{
	font-size: 12px;
	margin-top: 5px;
	padding: 0 $padding * 2;
	text-transform: none;
}