@import 'src/styles/variables';

.wrapper {
	padding: $padding;
	font-size: 16px;
	text-align: center;
}

.error {
	color: $red;
}

.button {
	margin: $padding / 2 5px 0;
}
