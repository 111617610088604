@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: $padding;
	text-align: center;
}

.title {
	align-self: stretch;
	margin-bottom: 10px;
	color: $secondary;
	font-size: 24px;
	font-weight: 600;
	text-transform: uppercase;
}

.subtitle {
	align-self: stretch;
	color: $secondary;
	font-size: 18px;
}

@value photoWidth: 120px;
.photo {
	width: photoWidth;
	height: photoWidth;
	margin: 35px 0;
	line-height: 1;
	border-radius: 50%;
	overflow: hidden;
}

.btnContacts {
	margin: 15px 0;
}
