@import 'src/styles/variables';

.wrapper {
	padding: 50px 0;
	color: $grey;
	font-size: 13px;

	@include media('>laptop') {
		position: absolute;
		top: 60px;
		right: 80px;
		padding: 0;
	}
}

.link {
	display: block;
	margin-top: 10px;
	color: $black;
	font-size: 14px;
	line-height: 1;
	transition: $duration color;

	@include mouse {
		&:hover {
			color: $primary;
		}
	}
}

.arrow {
	display: inline-flex;
	vertical-align: 4px;
	align-items: center;
	margin-right: 5px;

	&::before {
		content: '';
		height: 1px;
		width: 27px;
		background-color: $primary;
	}

	&::after {
		content: '';
		width: 0;
		height: 0;
		border: 3px solid transparent;
		border-left: 5px solid $primary;
	}
}
