@import "src/styles/variables";

.buttonWrapper {
  margin-bottom: $padding * 2;
  text-align: center;
  padding-top: 15px;

  @include media("<laptop") {
    margin-top: $padding * 2;
  }
}

.mobileTitle {
  display: flex;
  align-items: center;

  .photo {
    margin-right: 15px;
  }
}

@value photoWidth: 120px;
.photo {
  width: photoWidth;
  max-height: 100px;
  object-fit: contain;
}
