@import "src/styles/variables";

.link {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 200px + $padding * 2;
	padding: 15px $padding 10px;
	display: block;
}

$dash: 190px 70px 130px 350px 50px 180px 190px 430px 210px 490px 60px 220px 230px;
$stroke: 14px 14px 14px 14px 14px 16px 16px 16px 17px 17px 17px 18px 20px;
$duration: 0.5s 0.4s 0.5s 0.5s 0.3s 0.5s 0.5s 1s 0.5s 1s 0.5s 0.3s 0.6s 0.5s;
$delay: 0s 0.1s 0.2s 0.4s 0.6s 0.7s 0.9s 1.1s 1.3s 1.5s 2s 2.2s 2.3s;
$duration2: 0.7s 0.6s 0.7s 0.7s 0.5s 0.7s 0.7s 1.2s 0.7s 1.2s 0.7s 0.5s 0.8s 0.7s;
$delay2: 0.2s 0.3s 0.4s 0.6s 0.8s 0.9s 1.1s 1.3s 1.5s 1.7s 2.2s 2.4s 2.5s;

@keyframes logo {
	100% {
		stroke-dashoffset: 0;
	}
}

.logo {
	width: 100%;

	path {
		stroke: $primary;
		animation: logo $quint both;

		@for $i from 1 through 13 {
			&:nth-child(#{$i}) {
				stroke-width: nth($stroke, $i);
				stroke-dasharray: nth($dash, $i);
				stroke-dashoffset: nth($dash, $i) + 1px;
				animation-duration: nth($duration, $i);
				animation-delay: nth($delay, $i);
			}
		}
		// @include ie_edge{
		// 	stroke-dasharray: 0px !important;
		// }
	}

	:global(.blue) path {
		stroke: $secondary;

		@for $i from 1 through 13 {
			&:nth-child(#{$i}) {
				animation-duration: nth($duration2, $i);
				animation-delay: nth($delay2, $i);
			}
		}
	}
}

.logoPosition {
	position: relative;
	width: 200px;
}
