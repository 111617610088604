@import 'src/styles/variables';

.btn {
	min-width: 80px;
	text-align: center;
	transition: $duration background-color;

	@include media('<tablet') {
		min-width: 60px;
	}

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}

	&.isAlertesOpen {
		background-color: $primary;
	}
}

.svg {
	margin-top: 6px;
	width: 27px;
	stroke: $secondary;
	transition: $duration stroke;

	@include ie {
		vertical-align: -20px;
	}

	@include media('<laptop') {
		stroke: $primary;
	}

	.isAlertesOpen & {
		stroke: white;
	}
}
