@import "mixins";

// Bermudes
$yellow: #ffe15d;
$purple: #6a4796;
$lightGreen: #86d723;

// The Buyer
$oldRose: #c78786;
$darkBlue: #03142f;

// Stats The Buyer
$watermelon: #ff5c79;

// Main colors
$green: #86d723;
$red: #d0021b;
$black: #000;

// Greys
$darkGrey: #646464;
$grey: #8f8f8f;
$lightGrey: #d8d8d8;
$ultraLightGrey: #f7f8f9;
$background: #eff0f2;
$line: #d8d8d8;

// Refs
$primary: $oldRose;
$secondary: $darkBlue;
$text: $secondary;

// Shadows
$shadow: 0 2px 15px rgba(219, 221, 226, 0.5);
$greyShadowHeight: 25px;

// Radius
$radius: 2px;

// Margin / Padding
$padding: 20px;

// Header
$headerHeight: (
	small: 60px,
	big: 80px,
);

// Footer
$footerHeight: (
	small: map-get($headerHeight, small),
	big: map-get($headerHeight, big),
);

// Taille du cercle avec initiales du BtnMenu
$btnMenuCircleSize: 38px;

// Hauteur de la barre des filtres
$filtresHeight: 45px;

// Fonts
$kelson: "kelson", arial, sans-serif;
$font: $kelson;

// Transitions
$duration: 0.2s;
$quint: cubic-bezier(0.7, 0, 0.3, 1);
$bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutQuad: cubic-bezier(0.46, 0.03, 0.52, 0.96);

// Breakpoints
$breakpoints: (
	mobile: 425px,
	tablet: 768px,
	laptop: 1024px,
	desktop: 1440px,
	xxl: 2560px,
);
