@import 'src/styles/variables';

.h2 {
	position: relative;
	padding: $padding $padding 0;
	color: $primary;
	font-size: 20px;

	@include media('<=tablet') {
		padding: 10px;
		color: #fff;
		text-align: center;
		background-color: $secondary;

		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: $padding;
			bottom: 0;
			width: 0;
			height: 0;
			margin: auto;

			border: 6px solid transparent;
			border-bottom: none;
			border-top: 8px solid currentColor;

			transform: rotate(-90deg);
		}

		&.isOpen {
			&::after {
				transform: rotate(0deg);
			}
		}
	}
}

.content {
	padding: 10px $padding $padding;

	@include media('<=tablet') {
		padding-top: $padding;

		&:not(.isOpen) {
			display: none;
		}
	}
}
