@import 'src/styles/variables';

.wrapper {
	flex-direction: column;
}

.list {
	display: flex;
	margin-top: 5px;
}
