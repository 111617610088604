// Imports
@import 'variables';
@import 'fonts';
@import '~react-datepicker/dist/react-datepicker.css';
@import 'outdated';
.d-flex{
	display: flex;

}
.flex-column{
	flex-direction: column;
}
.divid{
	background-color: #8f8f8f;
	height: 1px;
	width: 100%;
}
.mt-3{
	margin-top: 10px;
}
// Global
html {
	// Font
	color: $black;
	font-size: 14px;
	font-family: $font;
	line-height: 1.7;

	// Background
	background: $background;

	// Touch
	-webkit-tap-highlight-color: rgba(black, 0.1);

	// Inertie
	-webkit-overflow-scrolling: touch;

	// Selection
	&::selection {
		background: $primary;
		color: $primary;
	}

	// Disabled zoom on double-tap
	touch-action: manipulation;
}

strong {
	font-weight: 500;
}

// Scrollbar
$scrollbarColor: $lightGrey;
$scrollbarBgColor: $background;
* {
	scrollbar-width: 6px;
	scrollbar-color: $scrollbarColor $scrollbarBgColor;
}
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	background: $scrollbarBgColor;
}
::-webkit-scrollbar-thumb {
	background: $scrollbarColor;

	&:hover {
		background: darken($scrollbarColor, 10);
	}
}

// Button
button {
	border: none;
}

// Inputs
input,
textarea {
	font-size: 16px;
	border-radius: 0;

	@include ie {
		min-height: 30px;
	}

	&::placeholder {
		color: $grey;
		transition: $duration opacity;
	}

	&:focus {
		&::placeholder {
			opacity: 0.5;
		}
	}

	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}

// Animations
@keyframes pulse {
	0%,
	100% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
}

// datePicker
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range.react-datepicker__day--in-selecting-range {
	background-color: rgba($primary, 0.5);
}
.react-datepicker__day--in-range,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover {
	background-color: $primary;
}
