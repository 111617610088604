@import 'src/styles/variables';

.back {
	position: absolute;
	top: 0;
	left: 0;
	padding: 15px 15px;
	color: $grey;
	z-index: 1;
}

.title {
	padding: 10px 0 15px;
	font-size: 25px;
	line-height: 1;
	text-align: center;

	@include media('<=mobile') {
		font-size: 20px;
	}
}
