@import 'src/styles/variables';

.wrapper {
	display: flex;
	align-items: center;
	color: $text;
	line-height: 1;
	width: 18px;
	cursor: pointer;
	user-select: none;
}

.loading {
	cursor: not-allowed;
}

.input {
	// position: absolute;
	display:none;
	top: 0;
	left: 0;
	bottom: 0;
	width: 18px;
	height: 18px;
	margin: auto;
	opacity: 0;
}

.checkbox {
	display: block;
	position: relative;
	flex: 0 0 18px;
	height: 18px;
	border: 1px solid $line;
	border-radius: 2px;
	transition-duration: $duration;
	transition-property: color, border-color;

	@include mouse {
		.wrapper:hover & {
			color: $primary;
			border-color: currentColor;
		}
	}

	.input:focus + & {
		color: $primary;
		border-color: currentColor;
	}

	&::after {
		content: '';
		position: absolute;
		top: 2px;
		left: 2px;
		right: 2px;
		bottom: 2px;

		border-radius: inherit;
		background-color: currentColor;
		transition-duration: $duration;
		transition-property: opacity, transform;

		opacity: 0;
		transform: scale(0.5);

		.halfChecked & {
			opacity: 1;
			transform: scaleY(0.2);
		}

		.checked & {
			opacity: 1;
			transform: none;
		}
	}

	.loading & {
		@include pulse;
		color: $grey !important;
	}
}

.text {
	margin-left: 15px;
}
