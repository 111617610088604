@import "src/styles/variables";

.wrapper {
	padding: $padding;
	border-bottom: 1px solid $line;
	background-color: white;
}

.header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	font-weight: 600;
	line-height: 2;
	text-transform: uppercase;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.checkbox {
	margin-right: $padding;
}

.row {
	display: flex;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.key {
	flex: 0 0 50%;
	padding: $padding/2 $padding/2 $padding/2 0;
	color: $grey;
	font-size: 12px;
	font-weight: 600;
}

.value {
	padding: $padding/2 0;
}
