@import "src/styles/variables";

.buttonWrapper {
	margin-bottom: $padding * 2;
	text-align: center;
	padding-top: 15px;

	@include media("<laptop") {
		margin-top: $padding * 2;
	}
}
