@import 'src/styles/variables';

.form {
	display: flex;
	align-items: center;
	padding: 0 $padding;
}

.label {
	display: flex;
	align-items: center;
	position: relative;
	flex: 1;
	height: 35px;
	border-bottom: 1px solid $line;
}

.input {
	width: 100%;
	height: 100%;
	border: none;

	&::-ms-clear {
		display: none;
	}
}

.reset {
	position: relative;
	flex: 0 0 40px;
	height: 40px;
	color: $grey;
	cursor: pointer;
	transition: $duration color;

	@include mouse {
		&:hover {
			color: $darkGrey;
		}
	}

	&.disabled {
		display: none;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 2px;
		height: 15px;
		background-color: currentColor;
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.loupe {
	flex: 0 0;
}
