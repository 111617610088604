@import 'src/styles/variables';

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 250px;
	height: $filtresHeight;
	padding: 0 $padding;
	color: #fff;
	font-size: 20px;
	background-color: $primary;
	user-select: none;
}

.contact {
	padding: $padding;

	& + & {
		border-top: 1px solid $line;
	}
}

.type {
	font-size: 18px;
	color: $primary;
}

.email {
	color: $primary;
	font-weight: 500;
}

.tel {
	color: $grey;
}
