@import 'src/styles/variables';

.text {
	display: inline-block;
	width: 100%;
	height: 100%;
	line-height: 1;
	background-color: $ultraLightGrey;
	@include pulse;
}

.small {
	width: 33%;
}

.big {
	width: 66%;
}
