@import "src/styles/variables";

.mobileTitle {
	display: flex;
	align-items: center;

	.photo {
		margin-right: 15px;
	}
}

.orderBtn {
	display: block;
	position: relative;
	width: 50px;
	height: 50px;
	transition: $duration background-color;

	&.down {
		transform: rotate(180deg);
	}

	@include mouse {
		&:not(:disabled):hover {
			background-color: $lightGrey;
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: -10px;
		width: 20px;
		height: 20px;
		margin: auto;
		border: 1px solid $grey;
		border-left: none;
		border-bottom: none;
		transform: rotate(-45deg);
	}
}

@value photoWidth: 120px;
.photo {
	width: photoWidth;
	max-height: 100px;
	object-fit: contain;
}

.actuTitle {
	font-size: 16px;
	font-weight: 600;
}

.actuContent {
	line-height: 1.5;
}
