.content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}


.title {
    font-size: 36px;
    font-weight: 600;
}
