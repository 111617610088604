@import 'src/styles/variables';

.emptyFooter {
	@include media('<tablet') {
		height: 50px;
	}
}

.containFactureAvoir {
	display: flex;
	padding: 10px;
	justify-content: space-around;
	align-items: center;
	@include media('<tablet') {
		flex-direction: column;
	}
}
