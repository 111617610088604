@import 'src/styles/variables';

.wrapper {
	padding-bottom: 100px;
}

.buttons {
	display: flex;
	align-items: center;
	position: fixed;
	top: map-get($headerHeight, big);
	right: 0;
	padding: 30px;
	z-index: 1;
	transition-duration: 0.2s;
	transition-property: opacity, transform;

	@include media('<tablet') {
		justify-content: center;
		top: auto;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 15px;
		box-shadow: $shadow;
		background-color: #fff;
	}

	&.hidden {
		opacity: 0;
		transform: translateY(-20px);

		@include media('<tablet') {
			opacity: 1;
			transform: translateY(100%);
		}
	}
}

.submit {
	margin: 0 0 0 10px;
}

// Le CSS qui suit est importé dans les sous-components
.section {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	margin: $padding * 2;
	padding: $padding * 2 $padding * 2 $padding * 1.5;
	background-color: white;

	@include media('<=tablet') {
		margin: $padding;
		padding: $padding;
	}
}

.title {
	flex: 0 0 100%;
	margin-bottom: $padding;
	color: $secondary;
	font-size: 25px;
	line-height: 1;

	@include media('<=tablet') {
		font-size: 20px;
		text-align: center;
	}
}

.column {
	display: flex; // Pour empêcher le merge des margins
	flex-direction: column;
	flex: 0 0 calc(50% - #{$padding});

	@include media('<tablet') {
		flex: 100%;
	}
}

.subtitle {
	flex: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: $grey;
	font-size: 16px;
	text-transform: uppercase;
}
