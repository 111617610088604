@import 'src/styles/variables';

.wrapper {
	display: flex;
	min-height: $filtresHeight;
	margin: $padding * 2;
	margin-bottom: 0;
	border-radius: $radius;
	background: white;

	@include media('<laptop') {
		margin: $padding;
		margin-bottom: 0;
		display: flex;
        justify-content: space-between
	}
}

.title {
	display: flex;
	align-items: center;
	position: relative;
	padding: 0 $padding;
	font-size: 13px;
	font-weight: 500;
	text-transform: uppercase;
	border-right: 1px solid $background;

	@include media('<360px') {
		font-size: 11px;
		padding: 0 10px;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: -6px;
		width: 12px;
		height: 12px;
		margin: auto;
		border: 1px solid $background;
		border-left: none;
		border-bottom: none;
		background-color: #fff;
		transform: rotate(45deg);
	}
}

.skeleton {
	align-self: center;
	width: 100px;
	height: 15px;
	margin-right: $padding;
	background-color: $lightGrey;
	@include pulse;
}

.error {
	color: $red;
	font-size: 13px;
}
