.container {
  position: relative;
  width: 300px;
  height: 400px;
  cursor: pointer;
}

.dropzone {
  padding: 20px;
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #8f8f8f;
}

.dropzone:hover, .dropzone.dragging {
  border-color: #c78786;
}

.dropzone.dragging.has-pdf, .dropzone.has-pdf:hover {
  background-color: rgba(0, 0, 0, 0.75);
  border: none;
}

.dropzone.has-pdf {
  border: none;
}

.text {
  color: #8f8f8f;
  transition: color 0.2s ease;
}

.dropzone:hover .text, .dropzone.dragging .text {
  color: #c78786;
}

.dropzone.has-pdf:hover .text, .dropzone.dragging.has-pdf .text {
  color: white;
}
