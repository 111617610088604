@import 'src/styles/variables';

.wrapper {
	display: flex;
	flex-direction: column;
	margin: $padding;
	background-color: #fff;
	white-space: pre-line;

	@include media('>=laptop') {
		flex-direction: row;
		margin: $padding * 2;
	}
}

.column {
	flex: 0 0 25%;

	@include media('>=laptop') {
		&:not(:first-child) {
			border-left: 1px $background solid;
		}
	}
}
