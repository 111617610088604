@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 1px;
    padding: $padding;
    color: $grey;

    strong {
        color: $text;
        font-size: 16px;
    }

    & ~ & {
        @include media('>=laptop') {
            margin-top: 0;
        }
    }

    @include media('<laptop') {
        // border-bottom: 2px solid $primary;

        // &::after {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: -13px;
        //     width: 0;
        //     height: 0;
        //     margin: auto;
        //     border: 9px solid transparent;
        //     border-bottom: none;
        //     border-top: 13px solid $primary;
        // }
    }
}

.fournisseur {
    margin-right: auto;
}

.productNotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2px !important;
    color: $primary;
    background-color: rgba(#8f8f8f, .2);
    margin: 0px 8px 0px 0px;
    padding-left: 8px;

    strong {
        cursor: pointer;
        color: $primary;
        margin: 0px 8px;
        font-size: 12px;
    }
}

.dateLivraison:not(:last-child) {
    margin-right: auto;
}
