@import 'src/styles/variables';

.button {
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: $btnMenuCircleSize;
	height: $btnMenuCircleSize;
	color: $primary;
	transition: $duration color;

	&:disabled {
		pointer-events: none;
	}
}

.svg {
	position: relative;
	width: 15px;
	height: 15px;
}
