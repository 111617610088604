@import "src/styles/variables";

.wrapper {
	display: flex;
	position: relative;
	height: 100%;
	padding: $padding;
	padding-bottom: $padding / 2;
	border-radius: $radius;
	background-color: #fff;
	box-shadow: $shadow;
	overflow: hidden;
	transition: $duration box-shadow;
}

.active {
	box-shadow: $shadow, 0 0 0 1px $secondary;
}

.left {
	margin-right: $padding;
}

@value photoWidth: 120px;
.photo {
	width: photoWidth;
	height: 110px;
}

.right {
	flex: 100px;
	padding-bottom: 35px;
}

.enseigne {
	color: $secondary;
	font-weight: 600;
	text-transform: uppercase;
}

.secteur {
	margin-top: 5px;
	color: $secondary;
}

.link {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	position: absolute;
	right: 12px;
	bottom: 12px;

	border-radius: 50%;
	background-color: $secondary;
	transition: $duration background-color;

	@include mouse {
		&:hover {
			background-color: darken($primary, 10);

			.active & {
				background-color: $ultraLightGrey;
			}
		}
	}

	svg {
		width: 24px;
		fill: #fff;
		transition: $duration opacity;
	}

	.active & {
		background-color: #fff;

		svg {
			opacity: 0;
		}
	}
}

.close {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 30px;
	height: 30px;
	margin: auto;
	transition: $duration opacity;

	.wrapper:not(.active) & {
		opacity: 0;
	}

	&::before,
	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 20px;
		height: 1px;
		background-color: $secondary;
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}
