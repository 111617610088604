@import 'src/styles/variables';

$itemHeight: 40px;

.title {
	display: flex;
	justify-content: center;
	align-items: center;
	height: $filtresHeight;
	padding: 0 $padding;
	color: white;
	font-size: 20px;
	background-color: $primary;
	user-select: none;
}

.list {
	max-height: $itemHeight * 10;
	overflow-y: scroll;
}

.link {
	display: flex;
	align-items: center;
	height: $itemHeight;
	padding: 0 $padding;
	white-space: nowrap;
	transition-duration: $duration;
	transition-property: color, background-color;

	&:hover {
		background-color: $ultraLightGrey;
	}
}

.active {
	color: $primary;
}
