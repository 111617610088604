@import 'src/styles/variables';

.wrapper {
	display: flex;
	margin: $padding * 2;
	margin-bottom: 0;
	border-radius: $radius $radius 0 0;
	background-color: white;
	box-shadow: inset 0 -10px 6px -8px $lightGrey;
	overflow: hidden;

	@include media('<tablet') {
		margin: $padding;
		margin-bottom: 0;
	}
}

.link {
	display: flex;
	position: relative;
	padding: 15px 40px;
	color: $darkGrey;
	white-space: nowrap;
	transition-duration: $duration;
	transition-property: color, background-color, box-shadow;

	@include media('<desktop') {
		padding-left: 20px;
		padding-right: 20px;
	}

	&:not(.active) {
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background-color: $ultraLightGrey;
		}
	}

	&:not(:first-child)::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 1px;
		height: 25px;
		margin: auto;
		background-color: $lightGrey;
		transition: $duration opacity;
	}

	@include media('<tablet') {
		&.active {
			flex: 1;
			padding-left: $padding;
			padding-right: $padding;
			font-size: 16px;
			border-bottom: 1px solid $line;
			box-shadow: none;
		}

		&:not(.active) {
			display: none;
		}
	}
}

.active {
	color: $primary;
	background-color: white;
	box-shadow: $shadow;
	cursor: default;

	&::after,
	& + .link::after {
		opacity: 0;
	}
}

.btnBack {
	margin-left: $padding * -1;
}
