@import 'src/styles/variables';

$optionHeight: 40px;
$focusColor: $primary;

.label {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		right: 20px;
		bottom: 16px;
		width: 0;
		height: 0;
		border: 5px solid transparent;
		border-top: 6px solid $primary;
		border-bottom: none;
		transform-origin: center 2px;
		transition: 0.2s transform;
	}

	&.open {
		&::after {
			transform: rotate(180deg);
		}
	}
}

.input {
	cursor: pointer !important;
}

.list {
	position: absolute;
	top: calc(100% - 2px);
	left: 1px;
	right: 1px;
	min-height: 3 * $optionHeight;
	max-height: 7 * $optionHeight;

	border: 1px solid $line;
	background-color: $ultraLightGrey;
	box-shadow: $shadow;

	z-index: 1;
	overflow: auto;
	overscroll-behavior: contain;

	transition-duration: 0.15s;
	transition-property: opacity, transform;

	.label:not(.open) & {
		opacity: 0;
		pointer-events: none;
		transform: translateY(10px);
	}
}

.item {
	position: relative;
	height: $optionHeight;
	padding: 0 15px;
	line-height: $optionHeight;
	cursor: pointer;

	transition-duration: 0.1s;
	transition-property: color, background-color;

	&:not(:first-child) {
		border-top: 1px solid $line;
	}

	@include mouse {
		&:hover {
			background-color: $lightGrey;
		}
	}

	&.active {
		color: #fff;
		background-color: $primary;
	}
}
