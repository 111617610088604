@import 'src/styles/variables';

$focusColor: $primary;

.label {
	display: flex;
	flex-direction: column-reverse;
	margin: 15px 0;
	span {
		color: #c78786;
	}
}

.input {
	height: 40px;
	padding-left: 15px;
	color: $text;
	border: 1px solid $line;
	background-color: $ultraLightGrey;
	transition: 0.2s border-color;
	appearance: none;

	.loading & {
		border-color: $ultraLightGrey;
		@include pulse;
	}

	@include mouse {
		&:hover,
		.label:hover & {
			&:not(:read-only) {
				border-color: $grey;
			}
		}
	}

	&:focus:not(:read-only) {
		border-color: $focusColor !important;
	}

	&:not(textarea):not([value='']):invalid {
		border-color: $red;
	}

	&:read-only {
		color: $darkGrey;
		cursor: not-allowed;
	}
}

.textarea {
	padding: 15px;
	height: 250px;
	resize: none;
}

.text {
	display: block;
	padding-bottom: 10px;
	color: $darkGrey;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;

	.label:hover &:not(:read-only) {
		color: $primary;
	}

	.input:focus + &:not(:read-only) {
		color: $focusColor !important;
	}

	.input:not(textarea):not([value='']):invalid + & {
		color: $red;
	}
}
