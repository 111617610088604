@import 'src/styles/variables';

.link {
	display: block;
	position: relative;
	padding: 8vw;
	text-align: center;

	@include media('>=tablet') {
		padding: 40px 80px;
	}
}

.client {
	background-color: white;
}

.fournisseur {
	color: white;
	background-color: $secondary;
}

.title {
	font-size: 20px;
	margin-bottom: 10px;

	@include media('>mobile') {
		font-size: 25px;
	}
}

.text {
	transition-duration: $duration;
	transition-property: opacity, transform;

	@include media('>mobile') {
		font-size: 16px;
	}

	.link:hover & {
		opacity: 0;
		transform: translateY(-20px);
	}
}

.button {
	margin-top: 20px;
	pointer-events: none;

	@include mouse {
		position: absolute;
		left: 50%;
		bottom: 55px;
		margin: 0;
		transition-duration: $duration;
		transition-property: opacity, transform;
		transform: translateX(-50%);

		.link:not(:hover) & {
			opacity: 0;
			transform: translateX(-50%) translateY(30px);
		}
	}

	.fournisseur & {
		color: $secondary;
		background-color: #fff;
	}
}
