@import 'src/styles/variables';
@import '../../../../Common/SideMenuWrapper/break';

.title {
	margin: 30px 0 5px;
	padding: 0 $padding * 2;
	font-size: 20px;
	user-select: none;

	@include media('<#{$break}') {
		padding-left: $padding;
		padding-right: $padding;
	}

	@include media('<laptop') {
		margin: 40px 0 25px;
		padding: 0;
		font-size: 25px;
		font-weight: 600;
		text-align: center;
	}
}

.error {
	padding: 0 $padding * 2;
	color: $red;
	font-size: 13px;
}
