@import 'src/styles/variables';

.wrapper {
	align-self: center;
	padding: $padding / 2;

	@include media('<tablet') {
		flex: 100%;
	}
}

.skeleton {
	display: inline-block;
	width: 120px;
	margin-left: 10px;
	background-color: $ultraLightGrey;
	@include pulse;
}
