@import 'src/styles/variables';

.wrapper {
	display: flex;
	position: relative;
	flex-shrink: 0;
	height: map-get($headerHeight, small);
	background-color: white;
	z-index: 1;

	@include media('>=tablet') {
		height: map-get($headerHeight, big);
	}
}

.logo {
	margin-right: auto;

	@include media('<tablet') {
		width: 170px;
	}
}

.nom{
	border: 1px solid black;
	padding: 11px;
}
