@import 'src/styles/variables';

$focusColor: $primary;

.label {
	flex-direction: row;
	flex-wrap: wrap;
}

.text {
	flex: 100%;
	margin-bottom: -10px;
}

.input {
	display: none;
}

.upload {
	margin-top: 10px;
	margin-right: 15px;
}

.open {
	margin-top: 10px;
}
