@import 'src/styles/variables';

.label {
	display: inline-block;
	margin-right: 4px;
	padding: 0 4px;
	height: 18px;
	color: white;
	line-height: 18px;
	font-size: 12px;
	font-weight: 600;
	border-radius: $radius;
	background: $green;
	user-select: none;
	text-transform: uppercase;
}

.div {
	padding-top: 8px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		width: 15px;
		height: 2px;
		background-color: $primary;
		top: 3px;
	}

.origine {
	display: block;
	text-transform: capitalize;
	font-size: 12px;
}

.mark {
	// display: block;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 12px;
}
}
