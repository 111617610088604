@import 'src/styles/variables';

.popin {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 600px;
	padding: $padding;
	text-align: center;

	h1 {
		font-size: 20px;
		// line-height: 1;
		margin-bottom: 10px;
	}

	p {
		margin-bottom: 15px;
	}
}
.divid{
	height: 1px;
	width: 100%;
	background-color: rgba(gray, .4);
	margin-bottom: 30px;
}

p.error {
	color: $red;
	margin-bottom: 0;
}

.doneCheck {
	display: inline-block;
	vertical-align: 1px;
	width: 10px;
	height: 16px;
	margin-left: 10px;
	color: $green;
	border: 4px solid $green;
	border-top: none;
	border-left: none;
	transform: rotate(45deg);
}
