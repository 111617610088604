@import "src/styles/variables";

.wrapper {
	display: flex;
	position: relative;
	height: 100%;
	padding: $padding;
	padding-bottom: 30px;
	border-radius: $radius;
	background-color: #fff;
	box-shadow: $shadow;
	//overflow: hidden;
	transition: $duration box-shadow;
	// @media screen and (max-width: 500px) {
	// 	height: 80%;
	// }
}

.content {
	position: relative;
	z-index: 10;
}

.inPanier {
	box-shadow: $shadow, 0 0 0 1px $primary;
}

.btnFavori {
	position: absolute;
	top: 0;
	z-index: 1;
	left: 0;
}

.left {
	margin-right: $padding + 30;
	width: 120px;
	height: 110px;

	.labels {
		width: 166px;
	}
}

@value photoWidth: 70px;
.photo {
	width: photoWidth;
	height: 110px;
	object-fit: contain;
	margin-left: 30px;
	@media screen and (max-width: 500px) {
		margin-left: 10px;
		width: 130px;
	}
}

.right {
	flex: 100px;
	padding-bottom: 55px;
	position: relative;
	z-index: 1;
}

.nom {
	font-weight: 600;
	word-break: break-word;
	text-transform: uppercase;
}

.subnom {
	font-weight: 400;
	font-size: 12px;
	color: #8f8f8f;
	word-break: break-word;
	text-transform: uppercase;
}

.prix {
	color: $primary;
	// position: absolute;
	// bottom: -9px;
}

// .prixOpen {
// right: 160px;
// }

// le .wrapper est nécessaire pour forcer le absolute
.wrapper .btnPanier {
	position: absolute;
	right: -18px;
	bottom: -10px;
	margin-right: 5px;
}

.wrapper .btnPanierDisabled {
	position: absolute;
	right: -18px;
	bottom: -10px;
	margin-right: 5px;
	background-color: gray;
	cursor: not-allowed;
}
.btnCommentaireVignette {
	position: absolute;
	right: 112px;
	bottom: -14px;
}
