@import "src/styles/variables";

.imgWrapper{
    max-width: 70px;
    max-height: 70px;
    margin: auto;
    img{
        max-width: 70px;
        max-height: 70px;
    }
}
