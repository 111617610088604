@import 'src/styles/variables';

.figure {
	display: block;
	user-select: none;
}

.img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.imgNotFound {
	display: block;
	fill: $grey;
	width: 100%;
	height: 100%;
}

.picto {
	display: flex;
	align-items: center;

	position: relative;
	top: 0;
	left: 0;
	bottom: 0;

	fill: $primary;
	transition-duration: 0.1s;
	transition-property: fill, stroke;

	@include media('>=laptop') {
		stroke: $primary;
		stroke-width: 0.1px;
	}

	.isActive:not(.isCategorie) & {
		fill: white;
		stroke: white;
	}

	svg {
		display: block;
		width: 50px;
		height: 50px;

		@include media('>=laptop') {
			width: 100%;
			height: 35px;
		}
	}
}
