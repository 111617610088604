@import 'src/styles/variables';

.wrapper {
	margin-top: -1px; // ça sert à masquer le border gris du bandeau qui est juste au-dessus
	padding: 25px $padding 30px;
	color: #fff;
	text-align: center;
	background-color: $red;
}

.title {
	margin-bottom: 10px;
	font-size: 16px;
	font-weight: 500;
}

.button {
	margin-top: 15px;
	color: $red !important;
}
