@import 'src/styles/variables';

.wrapper {
	flex-direction: column;
}

.list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
}

.item {
	flex: 1 0 20%;
	min-width: 130px;
}

.btnAddDate {
	align-self: flex-start;
}
