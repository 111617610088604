@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	width: 40px;
	height: 40px;

	fill: transparent;
	stroke: $black;
	transition-duration: $duration;
	transition-property: fill, stroke, opacity, background-color; // on laisse opacity si ça doit être animé

	@include mouse {
		&:hover {
			background-color: $ultraLightGrey;
		}
	}
}

.checked {
	fill: $red;
	stroke: $red;
}

.picto {
	height: 20px;
	fill: inherit;
	stroke: inherit;

	.animation & {
		animation: pop $duration;
	}
}

@keyframes pop {
	0%,
	100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
}
