@import 'src/styles/variables';

.wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.ligne {
	position: absolute;
	width: 15vw;
	left: 6.77%;
	top: 55.66%;
	transform: rotate(45deg);
	transform-origin: bottom left;
	border-bottom: 1px solid $primary;
}

.ligne2 {
	position: absolute;
	width: 4vw;
	left: 26.77%;
	top: 29.6%;
	transform: rotate(-45deg);
	transform-origin: bottom left;
	border-bottom: 1px solid $primary;
}

.losange {
	position: absolute;
	width: 10vw;
	height: 10vw;
	left: 26.77%;
	top: 38.34%;
	transform: rotate(-45deg);
	background-color: #d8d8d8;
	opacity: 0.27;
}

.bigLosange {
	position: absolute;
	width: 25vw;
	height: 25vw;
	right: 20%;
	top: 30%;
	transform: rotate(-45deg);
	border: 1px solid $primary;
}

.ligne3 {
	position: absolute;
	width: 3vw;
	height: 3vw;
	right: 20%;
	top: 25%;
	transform: rotate(-45deg);
	transform-origin: bottom left;
	border-bottom: 1px solid $primary;
}

.ligne4 {
	position: absolute;
	width: 10vw;
	right: 0;
	top: 40%;
	transform: rotate(45deg);
	transform-origin: bottom left;
	border-bottom: 1px solid $primary;
}
