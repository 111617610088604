@import 'src/styles/variables';
@import 'break';

.wrapper {
	max-height: 100%;
	padding: 0 $padding;
	overflow-y: auto;

	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}

	@include media('>=laptop') {
		width: 100%;
		max-width: 190px;
		padding: 0 0 $padding * 2 0;
		border-top: 2px solid $background;
		background-color: white;
	}

	@include media('>=#{$break}') {
		max-width: 230px;
	}

	&::after {
		content: '';
		display: block;
		height: 50px;
	}
}
