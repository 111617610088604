@import "src/styles/variables";

.form {
	background-color: white;
	flex-direction: column;
	// width: 70%;
	@include media("<=laptop") {
		width: 100%;
	}
}

	@include media(">tablet") {
		flex-direction: row;
	}


.btnSubmit {
	// margin: 40%;
	display: block;
	margin: 0 auto;
	@include media(">tablet") {
		align-self: flex-start;
	}
	@include media("<=mobile") {
		align-self: center;
		margin-left: 0;
	}
}
.photo {
	margin-top: 2%;
	width: 300px;
	height: 230px;
	h1 {
		color: #646464;
		font-size: 1rem;
		line-height: 1;
		padding-bottom: 10px;
		cursor: pointer;
		transition: 0.2s color;
	}
	img {
		max-width: 300px;
		max-height: 200px;
		object-fit: contain;
	}
}

.container {
	display: grid;
	// grid-template-columns: repeat(2, 1fr);
	// grid-template-rows: 1fr;
	grid-column-gap: 50px;
	// grid-row-gap: 0px;

	@include media("<=laptop") {
		display: flex;
		flex-direction: column;
	}
}
.table1 {
	background-color: white;
	padding: $padding * 2;
	grid-column: 1/2;
	// width: 50%;
}

.tableTitle{
	font-weight: 500;
	margin-bottom: 10px;
}

.deleteBtn {
	position: absolute;
	right: 60px;
	cursor: pointer;
}

.labelsContainer{
    display: flex;
}

.h {
	margin-top: 25px;
	display: block;
	padding-bottom: 10px;
	color: #646464;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}
.hh {
	display: block;
	padding-bottom: 10px;
	color: #c78786;
	font-size: 1rem;
	line-height: 1;
	cursor: pointer;
	transition: 0.2s color;
}

.error {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $red;
	font-size: 13 + 0px;
	line-height: 1.3;
	text-align: center;
	transition-property: height, opacity;
}

.addSousCategorie{
	margin: $padding * 1 0;
}