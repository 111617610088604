@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	align-items: center;
	position: relative;
	align-self: flex-end;
}

.uc {
	position: relative;
	margin-right: 7px;
	font-size: 13px;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0px;
		bottom: 3px;
		width: 6px;
		height: 6px;
		margin: auto;
		border: 1px solid $grey;
		border-top: none;
		border-left: none;
		transform: rotate(45deg);
		pointer-events: none;
	}
}

.moins,
.plus {
	position: relative;
	flex-shrink: 0;
	width: 25px;
	height: 25px;
	margin: 0 5px;
	color: white;
	border-radius: 50%;
	background-color: $primary;
	transition-duration: $duration;
	transition-property: color, background-color;

	@include mouse {
		&:not(:hover):not(:disabled) {
			background: $lightGrey;
		}
	}

	&:disabled {
		color: $grey;
		background-color: $ultraLightGrey;
		cursor: not-allowed;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 10px;
		height: 2px;
		margin: auto;
		background: currentColor;
	}
}

.plus::after {
	transform: rotate(90deg);
}

.labelInput {
	position: relative;
	margin-left: 5px;
}

.hiddenInput,
.inputQuantite {
	max-width: 6ch;
	padding: 0 2px;
	box-sizing: content-box;
	font-size: 12px;
	font-weight: 600;
	text-align: center;

	&:focus {
		color: $primary;
	}
}

.hiddenInput {
	position: absolute;
	visibility: hidden;
}

.dash {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border-bottom: 1px dashed $grey;

	.wrapper:not(:hover) .inputQuantite:not(:focus) ~ & {
		display: none;
	}
}

.unite {
	position: relative;
	margin-right: 7px;
}
