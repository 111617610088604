@import 'src/styles/variables';
@import '../../../../Common/SideMenuWrapper/break';

.wrapper {
	display: flex;
	position: relative;
	height: 85px;
	margin-bottom: 2px;
	padding: 0 ($padding * 2 + 10px) 0 $padding;

	font-size: 18px;
	line-height: 1.3;
	user-select: none;
	background-color: white;

	transition-duration: 0.1s;
	transition-property: color, background-color;

	@include media('>=laptop') {
		height: auto;
		margin: 0;
		padding: 10px $padding;
		color: $darkGrey;
		font-size: 13px;
	}

	@include media('>=#{$break}') {
		padding-left: $padding * 2;
		padding-right: $padding * 2;
	}

	@include mouse {
		&:hover {
			color: $black;
			background-color: $ultraLightGrey;
		}
	}

	&.isActive {
		color: #fff;
		background-color: $primary;

		&.isCategorie {
			color: $black;
			background-color: $background;
		}
	}

	&.isSubActive {
		color: $primary;
	}

	@include media('<laptop') {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: $padding;
			bottom: 0;
			width: 0;
			height: 0;
			margin: auto;
			border: 5px solid transparent;
			border-left: 8px solid $black;
		}
	}
}

.subWrapper {
	display: flex;
	align-items: center;
	position: relative;

	.withOffset & {
		margin-left: 40px;

		&::before {
			content: '';
			display: inline-block;
			width: 0;
			height: 0;
			margin-right: 5px;
			border: 5px solid transparent;
			border-left: 7px solid #fff;
		}
	}
}

.text {
	.picto + & {
		margin-left: 50px + $padding;

		@include media('>=laptop') {
			margin-left: 40px;
		}
	}
}

.picto {
	display: flex;
	align-items: center;
	position: absolute;
	// top: 0;
	left: 0;
	// bottom: 0;
	transition-duration: 0.1s;
	transition-property: opacity, transform;
	.isActive:not(.isCategorie) & {
		opacity: 0.8;
	}
	width: 50px;
	height: 50px;
	object-fit: contain;
	@include media('>=laptop') {
		width: 25px;
		height: 25px;
	}
}
