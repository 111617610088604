@import 'src/styles/variables';

.paragraph {
	margin-bottom: 10px;
}

.listDates {
	padding-left: 30px;
	list-style-type: disc;
}
