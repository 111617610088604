@import 'src/styles/variables';

.wrapper {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;

	stroke: white;
	background-color: $primary;
	border-radius: 50%;
	transition: $duration background-color;

	@include mouse {
		&:hover {
			background-color: darken($primary, 10);
		}
	}
}

.picto {
	margin-bottom: 2px;
	height: 18px;
	stroke: inherit;
	stroke-width: 2px;
}
