@import "src/styles/variables";

$btnDeleteSize: 20px + $padding * 2;

.wrapper {
	position: relative;
	margin-bottom: $padding;
	border: 1px solid $line;
	border-radius: $radius;
	//overflow: hidden;
}

// Bouton de suppression
.delete {
	position: absolute;
	top: 0;
	right: 0;
	width: $btnDeleteSize;
	height: $btnDeleteSize;
}

// Description du produit
.produitData {
	display: flex;
	padding: $padding/2 $btnDeleteSize $padding/2 $padding/2;
}

.img {
	flex: 0 0 42%;
	height: 70px;
    width: 70px;
	margin-right: $padding;
}

.svgPanier {
	width: 70px;
	height: 70px;
	margin-right: 20px;
}

.nom {
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
}

.code {
	color: $primary;
}

// Messages de warning (nouveau prix, disabled ou plus de tarif)
.warning,
.error {
	margin-bottom: 10px;
	padding: 0 $padding/2;
	color: $red;
	font-size: 12px;
	text-align: center;
}
.warning {
	color: $grey;
}

// Prix & quantité
.wrapperPrixQuantite {
	display: flex;
	margin-bottom: 10px;
	padding: 0 $padding;
}

.prix {
	margin-top: 60px;
	color: $primary;
}

.plusMoins {
	margin-left: auto;
}

// Total
.total {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px $padding;

	font-size: 12px;
	line-height: 1;

	background: $line;
}

.prixTotal {
	font-size: 18px;
}
